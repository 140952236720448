<template>
  <div coach-lesson>
    <div class="button-group" v-if="userCoachIdMatch">
      <basic-button theme="white" @click="onEdit">
        <svg-pencil />
        수업 소개 편집하기
      </basic-button>
    </div>
    <section-container theme="coach-detail">
      <heading>
        <template #prepend>
          <svg-timer />
        </template>
        <template #title>수업 가능 시간</template>
      </heading>
      <pre class="descriptive-text">{{ lessonTime }}</pre>
    </section-container>
    <section-container theme="coach-detail">
      <heading>
        <template #prepend>
          <svg-recommend />
        </template>
        <template #title>이런 분들께 추천합니다</template>
      </heading>
      <pre class="descriptive-text">{{ recommendation }}</pre>
    </section-container>
    <section-container class="lessons">
      <heading>수업 내용</heading>
      <section-container theme="coach-detail" v-for="lessonProduct in lessonProducts" :key="lessonProduct.lessonProductId">
        <div class="lesson-content">
          <heading theme="no-underline">
            <template #title>{{ lessonProduct.name }}</template>
            <template #sub v-if="lessonProduct.time">({{ getLessonProductTime(lessonProduct) }})</template>
          </heading>
          <point-icon :value="lessonProduct.price" shape="white-no-left-padding" />
          <template v-if="hasDescriptions(lessonProduct)">
            <hr />
            <ul class="text-list">
              <li v-for="(desc, i) in lessonProduct.description" :key="`${lessonProduct.lessonProductId}-${i}`">{{ desc }}</li>
            </ul>
          </template>
        </div>
        <svg-main-lesson class="icon-main-lesson" v-if="lessonProduct.isRepresentative" />
      </section-container>
    </section-container>
    <section-container theme="coach-detail">
      <drawer v-model="legalOpen" theme="base-legal">
        <template #cover>
          <heading theme="no-underline" class="legal-heading">상품정보제공고시 및 수강권 구매 취소 규정</heading>
        </template>
        <template #content>
          <LegalDesc />
        </template>
      </drawer>
    </section-container>
  </div>
</template>

<script>
import { getter } from 'shared/utils/storeUtils';
import Heading from '@/views/components/common/text/Heading.vue';
import SectionContainer from '@/views/components/coaching/SectionContainer.vue';
import { SvgTimer, SvgRecommend, SvgMainLesson } from '@/views/graphics/LazyLoadings';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import Drawer from '@/views/components/coaching/Drawer.vue';
import LegalDesc from '@/views/components/coaching/coach-detail/LegalDesc.vue';
import SvgPencil from '@/views/graphics/svg-pencil.vue';
import BasicButton from '@/views/components/common/button/BasicButton.vue';
import FloatEditAreaClass from '@/views/components/coaching/coach-detail/FloatEditAreaClass.vue';
import floatPopIn from '@/mixins/floatPopIn';

export default {
  name: 'CoachDetailClass',
  mixins: [floatPopIn()],
  components: { SvgPencil, LegalDesc, Drawer, PointIcon, SectionContainer, Heading, SvgTimer, SvgRecommend, SvgMainLesson, BasicButton },
  data: () => ({
    legalOpen: false,
  }),
  props: {
    coachInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    myCoachId: getter('auth', 'myCoachId'),
    userCoachIdMatch() {
      return this.myCoachId === this.ci?.coachId;
    },
    ci() {
      return /** @type{CoachDetail} */ this.coachInfo;
    },
    // 강의 가능 시간 설명
    lessonTime() {
      return this.ci?.lesson?.description?.lessonTime ?? '';
    },
    // 이런 분들께 추천합니다
    recommendation() {
      return this.ci?.lesson?.description?.recommendation ?? '';
    },
    lessonProducts() {
      return /** @type{CoachLessonProduct[]} */ this.ci?.lesson.lessonProducts ?? [];
    },
  },
  methods: {
    /**
     * @param {CoachLessonProduct} lesson
     * */
    hasDescriptions(lesson) {
      return lesson?.description?.length >= 1;
    },
    /**
     * @param {CoachLessonProduct} lesson
     * @return {String}
     */
    getLessonProductTime(lesson) {
      // this.$lessonTime을 추가하였으나 i18n 문자열이 부재하여 임시로 하드코딩함. 나중에 i18n 추가되면 i18n으로 작업할 것
      const units = {
        HOURS: '시간',
        MINUTES: '분',
        undefined: '',
      };
      return [lesson?.time?.value, units[lesson?.time?.unit]].join(' ');
    },
    onEdit() {
      this.$router.push({ name: 'CoachDetailClassEdit', params: { tab: 'class-edit' } });
    },
    getFloatPopInOptions() {
      return ({
        componentFloat: FloatEditAreaClass,
        optionsFloat: { onEdit: this.onEdit.bind(this), editMode: false },
        unregister: !this.userCoachIdMatch,
      });
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@c-gray-hr: #ebebeb;

[coach-lesson] {.pt(32);
  > [section-container] + [section-container] {.mt(48);}
  [heading] svg {.mr(5)}
  > [section-container] > [heading] {.mb(24)}
  .lesson-content [heading] {.mr(8)}
  [section-container].lessons > [section-container] {.flex;flex-direction: row;}
  .lesson-content {flex: 1; }
  .lesson-content > [heading] {.mb(16)}
  .lesson-content > hr {.wh(50, 1);border-top: solid 1px @c-gray-hr;.mt(16);.mb(16);}
  .lesson-content .text-list { .ul-disc-style() }
  [section-container].lessons {
    [section-container] + [section-container] {.mt(12);}
  }
  .descriptive-text {white-space: pre-wrap;.fs(16);.lh(24);}
  [drawer] {
    .legal-heading h2 {transition: color .5s ease-in-out; }
    &[data-open='false'] .legal-heading h2 {.c(@gray-500); }
  }

  .button-group {.flex;.flex-jc(flex-end);.mb(32);}

  @media (@tp-down) {
    .pt(24);
    > [section-container] + [section-container] {.mt(24);}
    > [section-container] > [heading] {.mb(19);}
    .descriptive-text {.fs(14);.lh(21);}
    .button-group {.hide}
  }
}

</style>
